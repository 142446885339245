import ApplicationLogo from '@/Components/ApplicationLogo';
import { Link } from '@inertiajs/react';
import { PropsWithChildren } from 'react';

export default function Guest({ children }: PropsWithChildren) {
    return (
        <div>
            <div className="header w-full flex fixed bg-white top-0 justify-center items-center p-4">
                <Link href="/">
                    <ApplicationLogo className="w-[82px] h-auto" />
                </Link>
            </div>
            
            <div className="min-h-screen py-24 px-4 flex flex-col sm:justify-center items-center bg-i-blue-to-indigo">
                <div className="w-full sm:max-w-md md:px-14 md:py-16 px-7 py-8 bg-white shadow-md overflow-hidden rounded">
                    {children}
                </div>
            </div>
        </div>
    );
}
